html,
body {
    height: 100%;
}
body{
    font-family: 'Roboto', sans-serif;
    font-kerning: normal;
    font-size: 16px;
}
 .main {
    padding: 15px 0px 15px 0px;
}

.cover {
    overflow-x: hidden;
    min-height: 85%;
    /* background: url('../img/tile.png') center center no-repeat; */
    background-size: contain;
    width: 100%;
}
a{
    color: #18a79f;
}
img {
    pointer-events: none;
}

header {
    padding: 0 15px;
    transition: 300ms ease-in-out;
    top:-130px;
}
.home header{
    border: none;
    z-index: 111;
}
body.sticked{
    padding-top: 105px;
}
header.sticky{
    position:fixed;
    top:0;
    left:0;
    z-index:999;
    width: 100%;
    max-width: initial;
    margin: 0;
    border: none;
    box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.2);
    background: #04312c;
    background: rgb(0,30,128);
    background: -moz-linear-gradient(left, rgba(0,30,128,1) 1%, rgba(0,172,152,1) 100%);
    background: -webkit-linear-gradient(left, rgba(0,30,128,1) 1%,rgba(0,172,152,1) 100%);
    background: linear-gradient(to right, rgba(0, 30, 128, 0.9) 1%,rgba(0, 172, 152, 0.9) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001e80', endColorstr='#00ac98',GradientType=1 );
}
header.sticky .navbar-burger{
    margin-top: 16px;
}
header.sticky .navbar{
    max-width:1200px;
    margin:auto;
}
header.sticky .navbar-brand img{
    max-height: 38px;
    margin: 20px 0px;
    transition: 300ms ease-in-out;
}
header.sticky .navbar-brand h1 {
    font-size: 20px;
    margin-top: 0;
    margin-left:10px;
    color: #fff;
}
header.sticky .navbar-item, .navbar-link{
    margin: 28px 5px;
}
#page{
    padding: 40px;
    min-height: 500px;
    text-align: center;
    border-top: 1px solid #f4f6fa;
}
#page .title{
    margin-bottom: 20px;
}
/*** navbar ***/
.navbar {
    min-height: 5.25rem;
    background: transparent;
    max-width: 1200px;
    margin: auto;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    align-items: stretch;
    display: flex;
}


.navbar-brand img {
    max-height: 44px;
    margin: 30px 0px;
    margin-right: 10px;
    display: inline-block;
    vertical-align:middle;
    border-right: 1px solid rgba(255, 255, 255, 0.13);
    padding-right: 18px;
}
.navbar-brand h1 {
    display:inline-block;
    vertical-align: middle;
    font-size: 22px;
    color: #fff;
    font-weight: 400;
}
.navbar-item, .navbar-link{
    color: #000;
    position: relative;
    margin: 38px 5px;
    padding: 0px 12px;
    line-height: 28px;
    font-size: 13px;
    text-transform: uppercase;
    display: inline-block;
    border-radius: 35px;
    transition: 400ms ease-in-out;
}
a.navbar-item.is-active, a.navbar-item:hover, a.navbar-link.is-active, a.navbar-link:hover{
    background: transparent;
    color: #000;
}

.home .navbar-item, .home .navbar-link{
    color:#fff;
}
.home a.navbar-item.is-active,
.home a.navbar-item:hover,
.home a.navbar-link.is-active,
.home a.navbar-link:hover{
    background: transparent;
    color: #fff;
}

.home .navbar-item:after{
    content:"";
    width: 0;
    height:4px;
    display:block;
    background: #fff;
    transition:300ms ease-in-out;
    border-radius: 4px;
}

a.navbar-item.is-active:after, a.navbar-item:hover:after, a.navbar-link.is-active:after, a.navbar-link:hover:after{
    width: 100%;
}

.hero.is-primary {
    min-height: 500px;
    transition: 400ms;
    max-width: 100%;
    padding-top:130px;
    margin-top:-130px;
    background: rgb(0,30,128); /* Old browsers */
    background: -moz-linear-gradient(left, rgba(0,30,128,1) 1%, rgba(0,172,152,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(0,30,128,1) 1%,rgba(0,172,152,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(0,30,128,1) 1%,rgba(0,172,152,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001e80', endColorstr='#00ac98',GradientType=1 ); /* IE6-9 */
}

.hero-index .hero-body{
    position: relative;
    padding: 0 15px;
    padding-top: 40px;
    max-width: 1200px;
    margin: auto;
    width: 100%;
    background-color: transparent;
    background-image: url(../assets/images/map.png);
    background-repeat: no-repeat;
    background-size: auto 80%;
    background-position: top center;
    display: flex;
    grid-auto-flow: dense;
    grid-template-columns: 100%;
    align-items: center;
    justify-items: center;
}

.hero-index .item{
   display:grid;
   grid-auto-flow: dense;
   grid-template-columns:50% 50%;
   padding: 40px 0  20px 0;
   align-items: center;
}
.hero-index .owl-dots {
    text-align: center;
    padding-top: 0;
    padding-bottom: 30px;
}
.hero-index .owl-carousel button.owl-dot {
    display: inline-block;
    background: rgba(0, 0, 0, 0.13);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin: 0 4px;
    outline: none;
}
.hero-index .owl-carousel button.owl-dot.active {
    background: rgba(255, 255, 255, 0.73);
}
.hero-index .image {
    justify-self: center;
    text-align: center;
}
.hero-index .image img{
    max-height: 240px;
    width: auto;
    border-radius: 5px;
    margin: auto;
}
.hero-index .description{
    position:relative;
}
 .hero-index .description h1{
     color:#fff;
     font-weight: 400;
     font-size: 40px;
     margin-bottom: 10px;
     margin-top: 10px;
     text-transform: capitalize;
 }

 .hero-index .description p{
     color: rgba(255, 255, 255, 0.8);
     font-weight:normal;
     font-size: 16px;
     margin-bottom:30px;
     max-width: 400px;
     line-height: 1.8;
 }
 .hero-index .description a{
     color:#fff;
     font-weight:normal;
     font-size: 14px;
     line-height: 32px;
     border-radius: 25px;
     border: 1px solid #fff;
     padding:0 32px;
     display:inline-block;
 }
.hero-index .description .status{
    position:absolute;
    top:-32px;
    font-size:14px;
    text-transform:capitalize;
}
.hero-index .description .status:before,
.page-title .status:before {
    content: "";
    display: inline-block;
    background: #fff;
    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 50%;
    vertical-align: unset;
}
.article-body {
    padding: 0 20px;
    max-width: 780px;
    margin: 40px auto;
}

.article-body h2 {
    font-weight: 500;
    font-size: 22px;
    padding: 20px 0px 20px 0px;
}

.photogrid {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 72px 0px 0px 0px;
}

.photogrid .card {
    margin: 5px 5px 30px 0px;
}

.is-big-card {
    width: 100%;
}

.card {
    margin: 15px 0px 0px 0px;
}

.card-body {
    padding: 4px;
}

.card-title {
    font-size: large;
    font-weight: 600;
}

.card-text {
    font-size: small;
    color: grey;
}

.photo-special h3 {
    font-size: large;
    font-weight: 600;
}

.card-body-special {
    position: absolute;
    background: #ffffff52;
    width: 100%;
    margin-top: 25px;
    padding: 5px 0px 8px 20px;
    margin-left: -112px;

}

.card-body-special .card-title {
    color: white;
}

.card-body-special .card-text {
    color: white;
    margin: 3px 0px 3px 0px;
}

.special .image.is-4by3 {
    padding: 20%;
}

.user {
    text-align: center;
    margin: 0px 8px 0px 8px;
}

.user-logo {
    width: 30%;
    margin: 15px auto;
}

.user-about {
    text-align: justify;
    margin: 10px 0px 0px 0px;
}

.user-about p {
    margin: 10px 0px 20px 0px;
}

.social {
    float: left;
    width: 100%;
    text-align: center;
}

.social img {
    display: inline-block;
    margin: 10px 10px 10px 10px;
}

.content .credit {
    margin-bottom: 0.2em !important;
}

/* FOOTER */

.footer {
    background-color: #f4f6fa;
    padding: 0;
    padding-top: 4rem;
    padding-bottom: 4rem;
}
.footer .container{
    padding: 0 20px;
}

.footer .pulsetic-badge {
    position: absolute;
    right: 0;
}

footer {
    position: relative;
}

footer .columns {
    padding-top: 30px;
    padding-bottom: 30px;
}

footer .footer-column .footer-header {
    padding: 10px 0;
}

footer .footer-column ul.link-list {
    line-height: 30px;
    font-size: 1rem;
}
footer .footer-column h3{
    font-weight: 400;
    color: #3f4451;
    font-size: 1.3em;
}
footer .contact{
        color: #677294;
        padding:8px 0;
        font-size:14px;

}
footer .contact span{
    font-weight:bold;
}
footer .contact a{
    color:#2aaf54;
}
footer .footer-column ul.link-list a {
    color: #677294;
    font-weight: 400;
    transition: all 0.5s;
    padding:0 15px;
}
footer .footer-column ul.link-list i,
footer .footer-column ul.link-list svg {
    font-size: 7px;
    vertical-align:middle;
    margin-right: 10px;
}
footer .footer-column ul.link-list a:hover{
    color:#2aaf54;
}
footer .social-links i,
footer .social-links svg{
    color: #858da8;
    transition: all 0.5s;
    font-size: 20px;
    vertical-align: middle;
}

.footer-logo {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 30px;
    margin-top:8px;
    color: #3f4451;
}
.footer-logo img{
    display:inline-block;
    width: 32px;
    vertical-align:top;
    margin-right:10px;
}
.footer-logo span{
    vertical-align:middle;
}
.footer-about{
    color:#677195;
    font-size: 16px;
    text-align: center;
    padding-top: 2rem;
}
.footer-about a{
    color:#2aac52;
}
.footer .social-links a {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    font-size: 14px;
    line-height: 45px;
    color: #858da8;
    display: inline-block;
    background: #ebeef5;
    text-align: center;
    transition: all .2s ease-in-out;
    margin: 10px 5px;
}
.footer .social-links a:hover {
    background:#2aac52;
    color:#fff;
}
.footer .social-links a:hover svg{
    color:#fff;
    transition:all .1s ease-in-out;
}

/* SERVICES */

#services {
    padding: 4rem 1.5rem 4rem 1.5rem;
}

.section .section-title-wrapper {
    position: relative;
    padding: 40px 20px;
}

.section .title.section-title {
    font-size: 44px;
    color: #3f4451;
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
}
.section .title.section-title:not(:last-child) {
    margin-bottom: 0.5rem;
}

.section-title-wrapper .subtitle {
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
    padding: 20px 25%;
    color: #677294;
    text-align: center;
}


.startup-icon-box .box-title {
    font-size: 32px;
    text-transform: uppercase;
    line-height: 22px;
    font-weight: 400;
    color: #444F60;
    padding: 0.5em 0;
}

.startup-icon-box .box-content {
    color: #999;
    font-size: 1.2em;
}
.startup-icon-box .more{
    color: #36aa90;
    font-size: 18px;
}
.startup-icon-box .more i,
.startup-icon-box .more svg{
    font-size: 1em;
    height: 0.8em;
    vertical-align: middle;

}

.content pre {
    white-space: pre-wrap;
}

.post-article-header {
    font-weight: 500;
    font-size: 24pt;
}

.post-article-body {
    margin: 0px 0px 10px 0px;
}

.post-article-teaser {
    margin: 10px 0px 0px 0px;
    text-align: justify;
}

.tags {
    color: #ffffff;
    float: right;
}

.article-header {
    height: 350px;
    background: lightgrey;
    padding: 0px 8px 0px 8px;
}

.article-header h1 {
    font-weight: 500;
    font-size: 32pt;
    margin: 100px 0px 10px 0px;
    color: white;
    text-shadow: 1px 1px lightgrey;
}

.article-header span {
    color: white;
}

.article-header .tags {
    color: #ffffff;
    float: left;
    padding: 15px 0px 10px 0px;

}

.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 4px;
    float: right;
}

.pagination li {
    display: inline;
    margin: 5px;
    text-align: center;
}

.pagination li a {
    margin-left: 5px;
}

.pagination .active {
    background: #D3D3D3;
}


.links-tip {
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: -20px 0px 10px 0px;
}

.links-tip ul {
    margin-left: 0px;
}

.links-tip li {
    float: left;
    list-style: none;
    margin-left: 5px;

}

.tip {
    background: rgba(245, 245, 245, 0.95);
    margin: 0px 0px 25px 0px;
    display: flex;
    flex-direction: column;
    padding: 5px 5px 0px 5px;
}

.content figure {
    margin-left: 0px;
    margin-right: 0px;
}
.navbar-burger {
    margin-top: 28px;
    border-radius: 5px;
    font-size: 22px;
    text-align: center;
    line-height: 52px;
    color: #888;
    display: none;
}

.home .navbar-burger{
    color: #fff;

}
 .startup-icon-box {
    padding-left: 140px;
    padding-right: 20px;
    position: relative;
    text-align: left;
    margin: 2em 0;
}

.startup-icon-box .icon {
    background-image: url(../assets/images/square.svg);
    background-repeat: no-repeat;
    background-size: contain;
    width: 120px;
    height: 120px;
    font-size: 32px;
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
}
.masthead {
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    margin-bottom: 40px;
}
 .masthead img{
     width:100%;
 }

.masthead .title {
    position: absolute;
    color: #fff;
    bottom: 40px;
    z-index: 1;
    left: 0;
    width: 100%;
    height: 58px;
    padding: 10px 30px;
    margin: 0;
}

.masthead .byline {
    position: absolute;
    bottom: 20px;
    left: 0;
    z-index: 1;
    color: #fff;
    padding: 0 30px;
}

.masthead .date {
    position: absolute;
    bottom: 20px;
    right: 0;
    z-index: 1;
    color: #fff;
    padding: 0 30px;
}
.post-list {
    position: relative;
    counter-reset: posts;
}
 .post-list .post {
 position: relative;
 padding: 0 20px;
 padding-bottom: 20px;
 transition: 300ms !important;
 counter-increment: posts;
 border-bottom: 1px solid #f4f6fa;
 }
 .post-list .post:not(:first-child){
      padding-top: 30px;
 }
 .post-list .post:last-child{
     border:none;
 }
    .post-list .post:after{
        content:"";
        display:block;
        clear:both;
    }
 .post-list .post:hover {
}
 .post-list .image {width: 140px;height: 100px;overflow: hidden;float: right;margin-left: 40px;position: relative;}
 .post-list .image img{
     max-width: 100%;
     max-height: 100%;
     border-radius: 2px;
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%,-50%);
     width: auto;
 }
 .post-list h1 {
    font-size: 28px;
    padding: 4px 0px 4px 0px;
    margin: 0 !important;
    font-weight: 500;
    line-height: 1.3em;
}
.post-list h1:after {
    content:"";
    display:block;
    width: 58px;
    height: 5px;
    transition:300ms;
    background:#d5dbe0;
}
.post:hover h1:after {
    background:#2aac52;
}

.post-list p{
    margin: 10px 0 !important;
    font-size: 16px;
    color: #686b71;
    overflow: hidden;
    font-weight: 400;
    transition:300ms;
}

.post-list .author {
   font-size: 14px;
   padding: 0px 0px 8px 0px;
   margin: 0 !important;
   line-height: 1.4em;
   color: #b2b8ca;
   transition:300ms;
}
.post:hover p,
.post:hover .author{
color: #313131;

}
.post-list .author i,
.post-list .author svg{
    font-size:12px;
    vertical-align: unset;
    margin-right:5px;
}
.post-list .date {
     font-size: 13px;
     color: #bbc0d0;
 }


.post-list h1 a {
    display: block;
    color: #3f4451;
    transition: 300ms;
}
.post:hover {box-shadow: 0px -50px 50px -30px inset rgba(0, 0, 0, 0.04);}
.post:hover h1 a {
    color:#2aac52;
}
/*** PROJECTS ***/
#projects{
    background: #f4f6fa;
}
.project {
    background: #ffff;
    border-radius: 5px;
    box-shadow: 0px -65px 0px 0px #f4f6fa inset;
    border: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.project .image {
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    height: 220px;

}
.project .image img{
    width: 100%;
    min-height: 220px;
    transition: 300ms ease-in-out;
}
.project h1 {
    font-size: 28px;
    margin: 20px 20px 0px 20px !important;
    font-weight: 400;
    text-transform: capitalize;
}

.project h1 a {
    color: #3f4451;
}

.project p {
    margin: 0 20px 20px 20px !important;
    font-size: 1em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.project p a {
    color: #677195;
}

.project .status {
    color: #677195;
    padding: 10px 20px 20px 20px !important;
    font-size: 16px;
    text-transform: capitalize;
}

.project .status:before {
    content: "";
    display: inline-block;
    background: #677195;
    width: 12px;
    height: 12px;
    margin-right: 10px;
    border-radius: 50%;
    vertical-align: unset;
}
.project[data-status="maintenance"] .status:before,
.hero-index .item[data-status="maintenance"] .status:before {
    background: #ce1753;
}
.fog canvas,.fog2 canvas{
    opacity: 0.4;
    animation: fadein 600ms;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 0.4; }
}
.project[data-status="development"] .status:before,
.hero-index .item[data-status="development"] .status:before  {
    background: #2196F3;
}

.project[data-status="in development"] .status:before,
.hero-index .item[data-status="in development"] .status:before  {
    background: #FFC107;
}
.project[data-status="released"] .status:before,
.hero-index .item[data-status="released"] .status:before  {
    background: #4CAF50;
}
.page-title p.status{
    display:inline-block;
    margin-bottom: 5px;
    opacity: 1;
    text-transform: capitalize;
}
.hero-index .item .status:before ,
.page-title .status:before {
     background:#fff !important;
 }
  .hero-index .item .status,
  .page-title  .status{
      border-radius: 25px;
      padding: 2px 10px;
      display: inline-block;
  }
 .hero-index .item[data-status="maintenance"] .status,
  .page-title .status[data-status="maintenance"] {
    background: #ce1753;
}

 .hero-index .item[data-status="development"] .status,
  .page-title .status[data-status="development"]  {
    background: #2196F3;
}

 .hero-index .item[data-status="in development"] .status,
  .page-title .status[data-status="in development"]  {
    background: #FFC107;
}
 .hero-index .item[data-status="released"] .status,
  .page-title .status[data-status="released"]  {
    background: #4CAF50;
}
.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    font-size: 34px;
    line-height: 45px;
    color: #858da8;
    display: inline-block;
    background: #ffffff;
    text-align: center;
    margin: 0 10px;
    outline: none;
}
.owl-carousel button.owl-dot{
    display:none;
}

.owl-nav {
    text-align: center;
    padding: 40px 0 0px 0;
}
.projects-grid {
    grid-gap: 30px;
    padding: 40px 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}
.projects-grid .project {
    grid-column: span 3;
    transition:300ms;
}
.projects-grid .project:hover {
    box-shadow:0px 0px 20px 0px rgba(0, 0, 0, 0.08) ,0px -65px 0px 0px #f4f6fa inset;
    transform:scale(1.04,1.04);

}
.projects-grid .project:hover .image img{
    transform:scale(1.5,1.5) rotate(10deg);

}
.page-title{
    position: relative;
    text-align: center;
    padding: 80px 20px 70px  20px;
    background: rgb(0,30,128); /* Old browsers */
    background: -moz-linear-gradient(left, rgba(0,30,128,1) 1%, rgba(0,172,152,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(0,30,128,1) 1%,rgba(0,172,152,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(0,30,128,1) 1%,rgba(0,172,152,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001e80', endColorstr='#00ac98',GradientType=1 ); /* IE6-9 */
}
section.page-title:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url(../assets/images/circles.svg);
    background-repeat: no-repeat;
    background-size: 60%;
    background-position: 50% 130px;
}
.home .page-title{
    padding-top: 190px;
    margin-top:-130px;
    min-height: 524px;
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.page-title h1{
    font-size: 32px;
    color: #ffffff;
    text-transform: uppercase;
    max-width: 680px;
    margin: 0;
}
 .page-title p{
    font-size: 16px;
    max-width: 480px;
    color: rgba(255, 255, 255, 0.8);
    margin: 0px !important;
}
.page-title .date{
    margin-top:12px;
    display: block;
    color: #fff;
}
.more-posts a {
    text-transform:uppercase;
    font-weight:500;
    line-height: 44px;
    border: 2px solid #f4f6fa;
    color: #b3bad7;
    width: 100%;
    max-width: 320px;
    display: inline-block;
    border-radius: 5px;
    font-size: 16px;
    transition:300ms ease-in-out;
}
.more-posts a:hover{
    border-color:#2aac52;
    color:#2aac52;

}
.more-posts {
    text-align: center;
    padding-top: 60px;
}
.copyright {
    font-size: 13px;
    line-height: 44px;
    background: #848ca9;
    color: #fff;
    text-align: center;
    margin-top: 2rem;
    font-weight: normal;
}
.featured-news-list > .title {
    font-size: 28px;
    color: #8289ab;
    padding-bottom: 60px;
    padding-top: 4px;
    font-weight: 400;
    position:relative;
    margin-bottom: 0;
}
.featured-news-list > .title:after{
    content:"";
    display:block;
    width: 6px;
    height: 40px;
    background:#43af46;
    position:absolute;
    top: 0px;
    left: -30px;

}


.featured-news-list .post h1 {
    font-size: 18px;
    font-weight: 500;
}
.featured-news-list .post:hover a{
  color:#43af46;
}
 .featured-news-list .post {
    position: relative;
    margin-bottom: 50px;
    border-bottom: 1px solid #dbe0e8;
    box-shadow: none !important;
    transition: 300ms ease-in-out;
    backface-visibility: hidden;
}
.featured-news-list .post:last-child{
    border:none;
}
.featured-news-list .date {
    position: absolute;
    top: -27px;
    font-size: 13px;
    color: #8289a9;
}

.featured-news-list .post h1 a {
    color: #3f4451;
     transition:300ms;
}

.featured-news-list .post p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #a3a9c0;
    font-size: 13px;
}

.featured-news-list {
    background: #f4f6fa;
    padding: 30px;
    border-radius: 5px;
}
.post-list-inner{
    max-width: 800px;
    margin: 50px auto;
}
.team-grid {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    margin:50px 0;
}
.team-grid .team-member{
    text-align:center;
    margin-bottom:40px;
}
.team-grid .team-member h1{
    font-size:18px;
    font-weight:bold;
    margin-top:20px;
    color: #3f4451;

}
.team-grid .team-member h1 a{
        color: #3f4451;
}
.team-grid .team-member p{
      color: #36aa90
}
.team-grid .team-member .image{
    position:relative;
    width:180px;
    height:180px;
    border-radius:50%;
    overflow:hidden;
    margin:auto;
}
.team-grid .team-member .image a{
    width:100%;
    height:100%;
    display:block;
}
.team-grid .team-member img{
    width:100%;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    filter:grayscale(100%);

}
.member-page h1{
    font-size:22px;
    font-weight:bold;
    color: #3f4451;
}
.member-page  .function{
    color: #36aa90;
    font-size:16px;
    margin-bottom: 20px;

}
.overview {
    padding: 8em 1em 0em 1em;
}

.overview h3 {
    font-size: 32px;
    color: #3f4451;
    text-transform: capitalize;
    margin-top: 60px;
}

.overview p {
    color: #677294;
    line-height: 2;
    font-size: 1.2em;
}

.mission-vision h4 {
    font-size: 32px;
    color: #3f4451;
    margin: 30px 0;
}

.mission-vision h4 svg {
    background: #009395;
    color: #fff;
    width: 44px !important;
    height: 44px;
    line-height: 44px;
    border-radius: 50%;
    display: inline-block;
    padding: 9px;
    vertical-align: middle;
}

.overview .image {
    width: 100%;
    overflow: hidden;
}

.overview .image img {
    width: 100%;
    padding: 10px;
    border-radius: 22px;
}
.contact-form h5 {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 12px;
}

.contact-form p {
    color: #848ca9;
    font-size: 1em;
    margin-bottom: 30px;
}
.contact-form {
    text-align: center;
    max-width: 640px;
    margin: auto;
    margin-bottom: 4em;
}
article.post h1 {
    font-size: 32px;
    font-weight: 400;
}

article.post p {
    font-size: 16px;
    line-height: 1.6;
    text-align: justify;
}

article.post {
    margin-bottom: 40px;
}

.social-buttons {
    margin-bottom: 80px;
    margin-top: 80px;
    border: 1px solid #f4f6fa;
    padding: 20px;
}
.social-buttons p{font-weight: 500;color: #848ca9;font-size: 16px;margin-bottom: 10px !important;}
.newsletter-box {
    background: #00a997;
    color: #fff;
    text-align: center;
    padding: 60px 40px 60px 40px;
    box-shadow: 0 10px 50px 0 rgba(12,0,46,.05);
    overflow: hidden;
    position: relative;
}
.newsletter-box img {
    width: 270px;
    position: absolute;
    top: -50px;
    left: 5%;
    opacity: 0.1;
}
.newsletter-box h3 {
    font-size: 28px;
    font-weight: 400;
}

.newsletter-box p {
    margin-bottom: 20px;
    color: rgba(255, 255, 255, 0.8);
}

.newsletter-box input[type="email"] {
    border: none;
    line-height: 44px;
    display: inline-block;
    max-width: 400px;
    width: 50%;
    border-radius: 5px 0 0 5px;
    height: 44px;
    padding: 0;
    vertical-align: middle;
    box-shadow: none;
    padding: 0 20px;
    outline: none;
    font-size: 16px;
}
::-webkit-input-placeholder {
    color: #a3a9c0;
    font-size: 13px;
  }
  ::-moz-placeholder {
    color: #a3a9c0;
    font-size: 13px;
  }
  :-ms-input-placeholder {
    color: #a3a9c0;
    font-size: 13px;
  }
  ::placeholder {
    color: #a3a9c0;
    font-size: 13px;
  }

.newsletter-box input[type="submit"] ,
.newsletter-box button {
    background: #00d4bc;
    color: #ffffff;
    border: none;
    line-height: 44px;
    margin: 0 -5px;
    padding: 0 30px;
    font-size: 18px;
    height: 44px;
    vertical-align: middle;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    box-shadow: none !important;
}
.newsletter-box button i,
.newsletter-box button svg{
    font-size: 12px;
    vertical-align: middle;
}
 .navbar-menu{
     display:block;
     flex-grow: 1;
     flex-shrink: 0;
     background: transparent;
     box-shadow: none;
 }
nav.social-links {text-align: center;}
@media screen and (max-width: 1088px){
    .navbar,
    header.sticky .navbar,
    .hero-index .hero-body,
    .container{
        max-width: 780px;
    }
    .featured-news-list{
        display: none;
    }
    #news .columns,
    .overview .columns,
    footer .columns,
    #services .columns{
        display: block !important;
        margin: 0 !important;
    }
    #news .column{
        padding:0;
    }
    #services .column{
        width: 100% !important;
    }
    .more-posts{
        padding-bottom: 60px;
    }

    .projects-grid{
        display: block;
        padding: 0 20px;
    }
    .projects-grid .project{
        max-width: 400px;
        margin: 30px auto;
    }
}
@media screen and (max-width: 768px) {
    .navbar-burger{
        display: none;
    }
    .navbar-brand h1{
        display:none;
    }
     .navbar-menu {
    }
    header.sticky .navbar-menu{
        top: 84px;
        border-radius: 0 0 5px 5px;
    }
    .navbar-item, .navbar-link{
        color: #fff !important;
    }
    .navbar-menu a{
        font-size: 14px;
    }
    header.sticky .navbar-item, .navbar-link{
    margin: 18px 5px;
    }
    .navbar-menu a::after{
        display: none;
    }
    .navbar-brand img{
        max-height: 55px;

    }
    .columns {
        margin-right: 0.0rem;
    }
    .team-grid{
        grid-template-columns: 33% 33% 33%;

    }
    .team-grid .team-member .image{
        width: 100px;
        height: 100px;
    }
    article {
        padding: 0;
    }

    .article-header {
        height: 100%;
        padding: 0px 0px 20px 14px
    }

    .article-header .tags {
        padding: 0px 0px 10px 0px
    }

    .cover {
        min-height: 80%;
    }

    .article-header h1 {
        margin: 0px 0px 10px 0px;
    }

    .tags {
        float: left;
        margin: 10px 0px 0px 0px;
    }

    .tags .tag {
        margin: 0px;
    }

    .tags:last-child {
        margin: 10px 0px 0px 0px;
    }

    .post-article-teaser {
        margin: 40px 0px 0px 0px;
    }

    .atitle .tags {
        float: left;
        margin: 10px 0px 0px 0px;
    }

    .content table {
        font-size: .75rem;
    }

    .content table td, .content table th {
        padding: .1em .3em;
    }
    .hero-index .hero-body{
        padding: 2em;
    }
    .hero-index .hero-body .image{
        position: static;
        display: block;
        margin: auto;
        max-width: 100%;
        width:240px;

    }

    section#news{
    }
    .post-list h1{
        font-size:28px;
    }
    .post-list p{
        margin: 10px 0;
        font-size:16px;
    }
    .hero-index .hero-body{
        margin: 0;
        padding: 0;
    }
    .hero-index .description h1{
        font-size: 2em;
        margin-bottom: 10px;
        padding-top: 20px;
    }
    .hero-index .description p{
        margin:20px;
        max-width:initial;
    }
    .hero-index .hero-body .image{
        width: 170px;
        margin-top: 50px;
    }
    .hero-index .item{
        display: block;
        text-align: center;
        padding: 40px 20px;
    }
    .hero-index .description .status{
        left: 50%;
        transform: translate(-50%,-50%);
        top: -10px;
    }
}
@media screen and (max-width: 640px) {
      .post-list .image{
        display: block;
        width: 100%;
        margin: 0;
        margin-bottom:30px;
        float: none;
        height:220px;
    }
    .post-list .image img{
        max-height:initial;
    }

}
@media screen and (max-width: 480px) {
    .navbar-brand img{
        max-height: 32px;
    }
    header.sticky .navbar-brand img{
         max-height: 32px;
         margin: 20px 0;
    }
    .navbar-menu a{
        font-size: 12px;
        margin: 24px 0px !important;
        padding: 0 6px;
    }
    header.sticky .navbar-item, .navbar-link{
        margin: 14px 2px !important;
    }
    header.sticky .navbar{
        min-height:initial;
    }
    body{
        font-size: 12px;
    }

    .team-grid{
        grid-template-columns: 50% 50%;

    }
    .team-grid .team-member .image{
        width: 100px;
        height: 100px;
    }
    .section-title-wrapper .subtitle{
        padding: 20px 0;
    }
    .post-list{
        grid-template-columns: auto;
    }
    .post-list .post:before{
        left: -74px;

    }
    .projects-grid{
        padding: 40px 20px;
    }
    body.home{
        background-size: auto 80%;
    }
    .hero.is-primary {
        height: auto;
    }

    .social-links{
        display: block !important;
        text-align: center;
        width: 100%;
    }
    .social-links a{
        display: inline-block;
    }
    section#news{
    }


    .navbar-brand h1{
        font-size: 16px;
    }

    .newsletter-box input[type="email"]{
        display:block;
        margin:auto;
        width:100%;
        border-radius:5px;
    }
     .newsletter-box input[type="submit"]{
        display:block;
        margin:auto;
        width:100%;
        border-radius:5px;
        margin-top:20px;
    }
     .post-list .image{
        height:180px;
    }
}

@media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) {

    .columns {
        margin-right: 0.0rem;
    }

    .is-hidden-mobile {
        display: none !important;
    }

    .article-header {
        padding: 0px 8px 20px 15px;
    }

    .content table {
        font-size: 14px;
    }

}
@media screen and (max-width: 350px){
    .navbar-brand img{
        border: none;
    }
    .navbar-brand h1{
        display: none;
    }
}

@media screen and (min-width: 1408px){
    .container {
        max-width: 1152px;
        width: 1152px;
    }
}




/*SOCAIL SHARE*/
a.facebook-share-button {
    position: relative;
    height: 20px;
    line-height: 20px;
    box-sizing: border-box;
    padding: 0px 8px 0px 6px;
    background-color: #3b5998;
    color: #fff;
    border-radius: 3px;
    font-weight: 500;
    cursor: pointer;
    vertical-align: top;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 10px;
}
a.linkedin-share-button{
    position: relative;
    height: 20px;
    line-height: 20px;
    box-sizing: border-box;
    padding: 0px 8px 0px 6px;
    background-color: #4875B4;
    color: #fff;
    border-radius: 3px;
    font-weight: 500;
    cursor: pointer;
    vertical-align: top;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
}
iframe#twitter-widget-1 {
    vertical-align: top;
}

iframe#twitter-widget-0 {
    vertical-align: top;
}

a.facebook-share-button i,a.facebook-share-button svg ,
a.linkedin-share-button i,a.linkedin-share-button svg{
    margin-right: 5px;
    margin-left: 2px;
}
.form {
    position: relative;
    z-index: 2;
}

.navbar-end {
    justify-content: flex-end;
    align-items: stretch;
    display: flex;
}
